import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
  padding: 0;
  height: 90vh;
  box-sizing: border-box;

  h2 {
    text-transform: uppercase;
    font-size: 22px;
    letter-spacing: 22px;
    text-align: center;
    margin: 36px 0 18px;
    span {
      letter-spacing: normal;
    }

    @media (max-width: 420px) {
      font-size: 20px;
      letter-spacing: 19px;
    }
  }

  p {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-align: center;

    a {
      color: inherit;
      text-decoration: none;
    }

    @media (max-width: 600px) {
      font-size: 14px;
    }

    @media (max-width: 420px) {
      font-size: 12px;
      letter-spacing: 2px;
    }
  }
`

const ImageWrapper = styled.div`
  width: 70%;
  max-width: 320px;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Wrapper>
      <ImageWrapper>
        <Image />
      </ImageWrapper>
      <h2>
        Coming soo<span>n</span>
      </h2>
      <p>
        <a href="https://instagram.com/luisa.abrahao">@luisaabrahao</a> |{" "}
        <a href="mailto:hello@luisaabrhao.com">hello@luisaabrahao.com</a>
      </p>
    </Wrapper>
  </Layout>
)

export default IndexPage
